<template>
  <div>
    <div
        v-if="showSuitMenu"
        class="suit-selector-wrapper">
      <div v-for="suit in selectableSuits" :key="suit"
           class="suit-selector suit-bg"
           style="font-family: Oswald!important;"
           :class="[suit, selectedSuit === suit ? 'selected': '']"
           @click="selectedSuit = suit">
        {{ $t('suits.' + suit) }}
      </div>
      <div class="suit-selector-fill"></div>
    </div>
    <div class="card-area"
         :class="[showSuitMenu ? selectedSuit + ' suit-bg border' : '']"
         :style="{maxWidth: (showSuitMenu ? '100%' : '80%')}">
      <pile :cards="cardsInSelectedSuit"
            :max-card-height-in-percentage-of-viewport-height="50"
            @select="$emit('select', $event)"/>
    </div>
  </div>
</template>

<script>
import Pile from "@/components/Pile";

export default {
  components: {Pile},
  props: {
    cards: Array,
    showSuitMenu: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      selectedSuit: '',
    }
  },
  computed: {
    selectableSuits() {
      return [...new Set(this.cards.map(c => c.suit))]
    },
    cardsInSelectedSuit() {
      if (!this.showSuitMenu)
        return this.cards
      return this.cards.filter(c => c.suit === this.selectedSuit)
    }
  },
  mounted() {
    if (!this.selectedSuit)
      this.selectedSuit = this.selectableSuits[0]
  }
}
</script>

<style scoped>
.suit-selector-wrapper {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  cursor: pointer;
}

.suit-selector {
  display: flex;
  flex-direction: row;
  vertical-align: middle;
  padding: 0.2rem 0.4rem;
  border: 1px solid white;
  box-sizing: border-box;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
}

.suit-selector-fill {
  display: flex;
  box-sizing: border-box;
  border-bottom: 1px solid white;
  width: 100%;
}

.suit-selector.selected {
  border-top: none;
}

.card-area {
  position: relative;
  display: flex;
  padding: 2% 7% 1% 1%;
  min-height: 80%;
  max-height: 100%;
  justify-content: center;
  overflow-x: visible;
}

.card-area.border {
  box-sizing: border-box;
  border: 1px solid white;
  border-top: none;
}

</style>