import { render, staticRenderFns } from "./CardDetail.vue?vue&type=template&id=dccb9f76&scoped=true&"
import script from "./CardDetail.vue?vue&type=script&lang=js&"
export * from "./CardDetail.vue?vue&type=script&lang=js&"
import style0 from "./CardDetail.vue?vue&type=style&index=0&id=dccb9f76&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dccb9f76",
  null
  
)

export default component.exports