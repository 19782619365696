<template>
  <img :src="require('@/assets/cards/' + this.$i18n.locale + '/' + imgName + '.jpg')"
       :srcset="require('@/assets/cards/' + this.$i18n.locale + '/' + imgName + '.jpg') + ' 2x'"
       class="card-img shadow"
       @click="$emit('select', card)"/>
</template>

<script>
export default {
  props: {
    card: Object,
  },
  computed: {
    imgName(){
      return this.card.imgName || this.card.name
    }
  }
}
</script>

<style scoped>
.card-img {
  border-radius: 4%;
  box-sizing: border-box;
}
</style>