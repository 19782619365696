<template>
  <span v-html="$t('scoring.' + scoringInfo.code, params)"></span>
</template>

<script>
export default {
  props: {
    scoringInfo: Object
  },
  computed: {
    params() {
      let res = {
        ...this.scoringInfo
      }
      if (this.scoringInfo.card)
        res.card = '<span class="card-name ' + this.scoringInfo.card.suit + '">'
            + this.$t('cards.' + this.scoringInfo.card.name)
            + '</span>'
      if (this.scoringInfo.refCard)
        res.refCard = '<span class="card-name ' + this.scoringInfo.refCard.suit + '">'
            + this.$t('cards.' + this.scoringInfo.refCard.name)
            + '</span>'
      if (this.scoringInfo.refCard2)
        res.refCard2 = '<span class="card-name ' + this.scoringInfo.refCard2.suit + '">'
            + this.$t('cards.' + this.scoringInfo.refCard2.name)
            + '</span>'
      if (this.scoringInfo.refCard3)
        res.refCard3 = '<span class="card-name ' + this.scoringInfo.refCard3.suit + '">'
            + this.$t('cards.' + this.scoringInfo.refCard3.name)
            + '</span>'
      if (this.scoringInfo.suit)
        res.suit =
            '<span class="suit ' + this.scoringInfo.suit + '">'
            + this.$t('suits.' + this.scoringInfo.suit)
            + '</span>'
      return res
    }
  }
}
</script>