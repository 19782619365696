<template>
  <div class="dialog modal-pane">
    <div class="dialog-content" @click="$emit('close')">
      <div class="card-image-cell vertical-middle">
        <div class="card-image">
          <card-image
              style="min-width: 20vw;"
              :card="card"/>
        </div>
      </div>
      <div class="card-detail-cell vertical-middle">
        <div class="card-detail-wrapper">
        <card-score :card="card" class="card-score"/>
        <div class="scoring-info-wrapper transparent-white-background" v-if="card.scoringInfos.length !== 0">
          <scoring-info
              class="scoring-info"
              v-for="(scoringInfo, idx) in card.scoringInfos"
              :key="card.name + '_si_' + idx + '_' + scoringInfo.code"
              :scoring-info="scoringInfo"/>
        </div>
        <div class="card-detail-button-area">
          <div
              v-if="card.relationType !== 'none'"
              class="button"
              style="margin-right: 0.3rem;"
              @click.stop="editRelation">{{ $t('editRelation') }}
          </div>
          <div
              class="button"
              @click.stop="removeFromPile">{{ $t('removeFromPile') }}
          </div>
        </div>
        </div>
      </div>
    </div>
    <div class="floating-button left" @click.stop="$emit('navigateCard', -1)">
      <div class="vertical-middle">
        <icon icon="chevron-left" size="2x"/>
      </div>
    </div>
    <div class="floating-button right">
      <div class="vertical-middle" @click="$emit('navigateCard', 1)">
        <icon icon="chevron-right" size="2x"/>
      </div>
    </div>
    <floating-close-button @close="$emit('close')"/>
  </div>
</template>
<script>
import CardImage from "@/components/CardImage2";
import ScoringInfo from "@/components/ScoringInfo";
import FloatingCloseButton from "@/components/FloatingCloseButton";
import CardScore from "@/components/CardScore";

export default {
  props: {
    card: Object
  },
  methods: {
    editRelation() {
      this.$router.push({name: 'editRelation', params: {cardName: this.card.name}})
    },
    removeFromPile() {
      this.$store.dispatch('resetCard', this.card.name)
    }
  },
  components: {CardScore, FloatingCloseButton, ScoringInfo, CardImage}
}
</script>

<style lang="scss" scoped>
.floating-button {
  position: fixed;
  top: 0px;
  display: table;
  text-align: center;
  width: 5%;
  height: 100%;
  cursor: pointer;

  &.left {
    left: 0px;
  }

  &.right {
    right: 0px;
  }
}

.vertical-middle {
  display: table-cell;
  vertical-align: middle;
}

.dialog {
  position: absolute;
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 100%;
  background-color: #000000AA;
}

.dialog-content {
  display: table-row;
  height: 90%;
  max-height: 90%;
}

.card-image-cell {
  display: table-cell;
  vertical-align: middle;
  width: 25%;
  text-align: center;
}

.card-image {
  text-align: center;
}

.card-detail-cell {
  position: relative;
  display: table-cell;
  vertical-align: middle;
  width: 50%;
  height: 100%;
  max-height: 100%;
  padding-right: 10%;
}

.card-detail-wrapper {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  min-width: 80%;
}

.card-score {
  display: flex;
  position: relative;
  top: 0;
  max-height: 10%;
  font-size: xx-large;
}

.large {
  font-size: x-large;
}

.scoring-info-wrapper {
  position: relative;
  display: block;
  border-radius: 5px;
  padding: 5%;
  max-height: 70%;
  overflow-y: auto;
}

.scoring-info {
  display: block;
  margin-bottom: 0.5rem;
}

.card-detail-button-area {
  position: relative;
  display: flex;
  justify-content: left;
  margin-top: 12px;
  max-height: 10%;
  min-height: 10%;
}

</style>