<template>
  <div class="wood-background"
       @drop="drop($event)"
       @dragover.prevent
       @dragenter.prevent>
    <card-detail
        v-if="selectedCard"
        :card="selectedCard"
        @navigateCard="navigateCard($event)"
        @close="selectCard(null)"/>
    <div class="heading-area">
      <div v-if="selectedPile > 0"
           class="heading">
        {{ $t('chooseHandCardsForPlayer', {player: selectedPile}) }}
      </div>
      <span v-if="selectedPile === 0"
            class="heading">
            {{ $t('addCardsToDiscard') }}
      </span>
    </div>
    <card-selector
        class="card-selector"
        :cards="allCards.filter(c => c.pile === -1)"
        @select="_addCardToPile({cardName: $event.name, pile: selectedPile})"/>
    <div
        class="add-pile-area">
      <pile class="pile-card-wrapper"
            :cards="cardsInSelectedPile"
            @select="selectCard($event)"
            @drop="drop($event)">
      </pile>
    </div>
    <floating-close-button
        @close="closeSelected"/>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import CardSelector from "@/components/CardSelector";
import FloatingCloseButton from '@/components/FloatingCloseButton'
import Pile from "@/components/Pile";
import CardDetail from "@/components/CardDetail";

export default {
  components: {CardSelector, FloatingCloseButton, Pile, CardDetail},
  computed: {
    ...mapGetters(['allCards', 'cardsInSelectedPile', 'selectedPile', 'freshGame', 'selectedCard']),
  },
  methods: {
    ...mapActions(['addCardToPile', 'selectCard', 'navigateCard']),
    closeSelected() {
      if (this.freshGame)
        this.$router.replace('/')
      else
        this.$router.go(-1)
    },
    _addCardToPile({cardName, pile}) {
      this.addCardToPile({cardName, pile}).catch(error => this.showWarning(error.message))
    },
    drop(event) {
      const cardName = event.dataTransfer.getData('cardName')
      if (cardName)
        this.addCardToPile({cardName, pile: this.selectedPile})
    }
  },
  mounted() {
    this.$nextTick(() => window.scrollTo(0, document.getElementById('app').scrollHeight));
  }
}
</script>

<style scoped>
.card-selector {
  position: absolute;
  top: 10%;
  align-content: center;
  left: 5%;
  right: 5%;
  max-height: 60%;
  height: 60%;
}

.add-pile-area {
  position: absolute;
  display: flex;
  align-content: center;
  top: 80%;
  max-width: 90%;
  height: 30%;
  align-self: center;
}

.pile-card-wrapper {
  overflow-x: hidden;
  border-top-left-radius: .3rem;
}

.pile-card-wrapper:last-child, .pile-card-wrapper:hover {
  overflow: visible;
}

.pile-card {
  min-width: 10em;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: center;
}
</style>